import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: 'home' */ '../pages/Home'));

const ServiceSubscription = lazy(
  () =>
    import(
      /* webpackChunkName: 'Service' */ '../pages/Subscription/subroutes/Steps'
    ),
);

const ConfigSubscription = lazy(
  () =>
    import(
      /* webpackChunkName: 'Service' */ '../pages/Subscription/subroutes/Configuration'
    ),
);

const routes = [
  {
    path: '/',
    name: 'Root',
    layout: '/admin',
    component: Home,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/subscription',
    name: 'Assinatura',
    layout: '/admin',
    component: ServiceSubscription,
    isPrivate: false,
  },
  {
    path: '/subscriptions/token=:subscriptionId',
    name: 'Configuração de Assinatura',
    layout: '/admin',
    component: ConfigSubscription,
    isPrivate: false,
  },
];

export default routes;
