/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import Environments from './environments';
import { getStoredAuthToken, getEnvironmentKey } from './helpers';

const envKey = getEnvironmentKey();
const environment = Environments[envKey];
const httpLink = new HttpLink({
  uri: environment.endpoint,
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      const isAuthError = graphQLErrors
        ? graphQLErrors.find(
            error => error.extensions?.exception?.name === 'TokenExpiredError',
          )
        : false;
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  },
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getStoredAuthToken();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: token || '',
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  link: from([errorLink, authMiddleware, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
