import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const reducer = (reducers: any) => {
  const persistedReducer = persistReducer(
    {
      key: 'trackpay',
      storage,
      whitelist: [''],
    },
    reducers,
  );
  return persistedReducer;
};

export default reducer;
